.tickets {
    &__header {
        border-bottom: 1px solid var(--neutral-grey-40);

        h2 {
            &:first-child {
                padding-left: 8px;
            }

            font-family: 'Rubik-Medium';
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            color: #37414b;
            padding: 14px 0;

            img {
                margin-left: 4px;
            }
        }
    }

    &__item {
        padding: 14px 0;
        font-size: 14px;
        line-height: 20px;
        color: var(--primary-black-default);
        border-bottom: 1px solid var(--neutral-grey-40);

        p:first-of-type {
            padding-left: 8px;
        }

        &__with-copy-btn {
            button {
                margin-left: 5px;
            }
        }
    }
}
