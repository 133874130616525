@font-face {
    font-family: 'Rubik-Regular';
    src: url('fonts/Rubik-Regular.ttf');
}
@font-face {
    font-family: 'Rubik-Medium';
    src: url('fonts/Rubik-Medium.ttf');
}
@font-face {
    font-family: 'Rubik-Light';
    src: url('fonts/Rubik-Light.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('fonts/Roboto-Medium.ttf');
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-family: 'Rubik-Regular';
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p,
h2,
h3 {
    margin: 0;
    padding: 0;
}

.plugin {
    &-loading {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    &-get-tickets {
        height: 184px;

        h3 {
            font-size: 14px;
            color: #787f88;
            margin-bottom: 8px;
        }

        button {
            width: 140px;
            height: 26px;
            border-radius: 4px;
            background: white;
            border: 1px solid var(--neutral-grey-40);
            color: #4d5772;
        }
    }

    .ticket-status {
        box-sizing: border-box;
        background: white;
        border-top: 1px solid var(--neutral-grey-40);
        padding: 8px;

        button {
            padding: 0 4px;
            border: 1px solid var(--neutral-grey-40);
            border-radius: 4px;
            color: #4d5772;
            width: auto;
        }
    }
}

.ticket-status-filter-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 60px;
        min-height: 20px;

        img {
            margin-left: 2px;
            height: 18px;
        }

        &:focus,
        &:hover {
            font-weight: bold;
            outline: none;
            border-radius: 3px;
            background-color: var(--state-lightblue-default);
            span {
                color: var(--neutral-white);
            }
        }
    }
}

.flex {
    display: flex;
    width: 100%;

    &.column {
        flex-direction: column;
    }
    &.between {
        justify-content: space-between;
    }
    &.center {
        justify-content: center;
    }
    &.end {
        justify-content: flex-end;
    }
    &.middle {
        align-items: center;
    }
    &.start {
        align-items: flex-start;
    }
    &.bottom {
        align-items: flex-end;
    }

    .f1 {
        flex: 1;
    }
    .f2 {
        flex: 2;
    }
}
